<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="grid p-fluid">
		<div class="col-12">
			<div class="card card-w-title">
				<Menubar :model="nestedMenuitems" :popup="true" :key="menuKey">
					<template #end>
						<div class="flex justify-content-end flex-wrap card-container green-container">
							<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
								Hedefler
							</div>
							
							<div class="flex align-items-center justify-content-center m-2">
								<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)" />
							</div>
						</div>
					</template>
				</Menubar>
			</div>
		</div>
	</div>
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="bm_hedefayrinti" :options="CrmDataTable_options" :isHeaderVisible="true" :rows="100" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["bm_bolgename", "bm_markaname"],
				views: [
					{ 'viewName': '2018', 'savedQueryId': 'F6E59C2F-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2019', 'savedQueryId': '8E360538-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2020', 'savedQueryId': '682B6248-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2021', 'savedQueryId': '7F314252-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2022', 'savedQueryId': '01A9FB5E-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2023', 'savedQueryId': '936EB36D-19C8-ED11-BC97-000C2920F782' },
					{ 'viewName': '2024', 'savedQueryId': 'DB33C2D4-32B5-EE11-AFA0-000C2920F782' },
				],
				forceSavedQueryId: 'DB33C2D4-32B5-EE11-AFA0-000C2920F782',
				preventSystemViews: true,
				preventUserViews: true
			},
			nestedMenuitems: [
				{
					label: "Hedef Analizi 2023",
					command: () => { this.GotoReport("SatisHedefleri", "yil"); },
					icon: "pi pi-fw pi-print"
				},
				{
					label: "Hedef Analizi 2024",
					command: () => { this.GotoReport2("SatisHedefleri2"); },
					icon: "pi pi-fw pi-print"
				},
			]
		}
	},
	created() {
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Hedefler');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}
	},
	methods: {
		onRowSelect(data) {
			debugger;
			
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_yedekparcateklifsiparis', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		GotoReport(Name, ParamName){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=2023";
			window.open(reportUrl, 'asd', '_blank');
		},
		GotoReport2(Name){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name;
			window.open(reportUrl, 'asd2', '_blank');
		},
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
